import styled from "styled-components";
import { NavLink } from "react-router-dom";
export const Search = styled.div`
    position: absolute;
    top: 168px;
    z-index: 450;
    left: 24px;
    width: 400px;
    background-color: white;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    @media (max-width: 1000px) {
      width: 300px;
    }
    @media (max-width: 800px) {
      top: 396px;
    }

}
`;
export const Container = styled.div`
background-color:#3f51b5;
`;
export const ContainerDane = styled.div`
  background-color: #3f51b5;
  padding-top: 24px;
  padding-left: 24px;
`;
export const Dane = styled.div`
  color: white;
  font-size: 20px;
  color: #feffff;
  font-family: "Exo 2", "Calibri", sans-serif;
  @media (max-width: 450px) {
      font-size:16px;
    }
`;
export const Menu = styled.ul`
display: flex;
    position: absolute;
    top: 110px;
    z-index: 450;
    left:24px;


`;
export const MenuElement = styled.li`
     &:nth-child(1) a{
      background-color: #3f51b5;
  color:white;
  border-radius:2px;
  margin-right: 4px;
  margin-left: 4px;
      }

`;
export const StyledLink = styled(NavLink).attrs()`
  width: 100%;
  text-decoration: none;
  color:#1f2961;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  font-family: 'Exo 2','Calibri',sans-serif;
  padding:8px 16px 8px 16px;
  border-radius: 2px;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14);
background-color:white;
;
`;
export const Wykres = styled.img`
    max-width: 900px;
    height: auto;
    width: 100%;
`;
export const WykresContainer = styled.div`
background-color: #3f51b5;
padding:24px;
@media (max-width: 650px) {
  padding-left:8px;
  padding-right:8px;
    }
`;

export const DropDownList = styled.ul`
cursor:pointer;
`;
export const ListElement = styled.li`
display:none;
cursor:pointer;
font-family: "Exo 2","Calibri",sans-serif;
padding: 12px 10px;
color:white;

`;
export const DropDownUp = styled.div`
cursor:pointer;
border: 1px solid #3f51b5;
    padding: 10px 20px;
    font-family: "Exo 2","Calibri",sans-serif;
    color:white;
    border-radius: 5px;
`;
export const DropDownContainer = styled.div`
margin-top:8px;
cursor:pointer;
width: 300px;
    background: #3f51b5;
    margin-left:24px;
    margin-top: 16px;
    padding: 14px 20px;
`;
export const Details = styled.div`
cursor:pointer;
font-weight: 600;
`;