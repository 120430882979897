import styled from "styled-components";

export const Container = styled.div`
`;
export const LogoImg = styled.img`
    width:110px;
    height:32px;
    margin-left: 64px;
`;
export const LogoLink = styled.a`

`;
